import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: './authentication/signin', pathMatch: 'full' },

      {
        path: 'advance-table',
        loadChildren: () =>
          import('./advance-table/advance-table.module').then(
            (m) => m.AdvanceTableModule
          ),
      },
      {
        path: 'getdata',
        loadChildren: () =>
          import('./getdata/getdata.module').then(
            (m) => m.GetDataModule
          ),
      },
      {
        path: 'address-table',
        loadChildren: () =>
          import('./address-table/address-table.module').then(
            (m) => m.AddressTableModule
          ),
      },
      {
        path: 'admin-table',
        loadChildren: () =>
          import('./admin-table/admin-table.module').then(
            (m) => m.AdminTableModule
          ),
      },
      {
        path: 'lecture',
        loadChildren: () =>
          import('./lecture/lecture.module').then(
            (m) => m.LectureModule
          ),    
      },
      {
        path: 'paxdetails',
        loadChildren: () =>
          import('./paxdetails/paxdetails.module').then(
            (m) => m.PaxDetailsModule
          ),    
      },
      {
        path: 'siteconfig',
        loadChildren: () =>
          import('./siteconfig/siteconfig.module').then(
            (m) => m.SiteConfigModule
          ),    
      },
      {
        path: 'banner',
        loadChildren: () =>
          import('./banner/banner.module').then(
            (m) => m.BannerModule
          ),    
      },
      {
        path: 'selfpaid',
        loadChildren: () =>
          import('./selfpaid/selfpaid.module').then(
            (m) => m.SelfPaidModule
          ),    
      },
           


      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'multilevel',
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule
          ),
      },
      {
        path: 'admin-log',
        loadChildren: () =>
          import('./admin-log/admin-log.module').then(
            (m) => m.AdminLogModule
          ),    
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
